<template>
  <div class="container-fluid">
    <h1 class="text-center">Influencers</h1>
    <div class="row">
      <div class="col">
        <transition name="component-fade" mode="out-in">
          <influencer-list :colSize="influencerColSize"
                           :influencers="influencers"
                           :influencerCurrentPage="influencerCurrentPage"
                           :influencerLastPage="influencerLastPage"
                           @more="loadMoreInfluencers">
          </influencer-list>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  const InfluencerList = () =>
  import('@/components/user/influencer/InfluencerList');
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export  default  {
    name       : 'Influencer',
    components : {
      InfluencerList,
    },
    data() {
      return {
        influencers           : [],
        influencerPerPage     : 8,
        influencerCurrentPage : 0,
        influencerLastPage    : 0,
      }
    },
    computed : {
      influencerColSize() {
        return this.getPortion(4)
      },
    },
    mounted(){
      this.getInfluencers(0);
    },
    methods : {

      /**
       * Get All Registered Influencers
       */
      getInfluencers(currentPage = this.influencerCurrentPage){
        this.$http.get('api/broadcast/influencers', {
          params : {
            page    : currentPage + 1,
            perPage : this.influencerPerPage,
          },
        }).then((response) => {
          this.influencerCurrentPage = response.data.currentPage;
          this.influencerLastPage = response.data.lastPage;
          if (this.influencerCurrentPage > 1) {
            const arr1 = this.influencers;
            const arr2 = response.data.data;

            this.influencers = arr1.concat(arr2);
          } else this.influencers = response.data.data;
        }).catch(() => {
          this.influencers = [];
        });
      },

      /**
       * Load Influencers
       */
      loadMoreInfluencers() {
        this.getInfluencers();
      },
    },
    mixins : [
      BreakpointMixin,
    ],
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/influencer";
</style>
